/* eslint-disable no-underscore-dangle */
import { InternalAxiosRequestConfig } from 'axios';
import { ApiConfig, HttpClient } from './httpClient';
import { Auth } from './Auth';

const { VITE_API_URL } = import.meta.env;

function authApi() {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return intercept(new Auth({ baseURL: VITE_API_URL }));
}

function intercept<T extends HttpClient>(api: T): T {
  const clearSessionAndReload = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.reload();
  };

  api.instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const impersonateId = localStorage.getItem('impersonateId');

    const headers = {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(impersonateId && { 'Act-As': impersonateId }),
    };

    return { ...config, headers } as InternalAxiosRequestConfig;
  });

  api.instance.interceptors.response.use(undefined, async (error) => {
    if (!error.response) {
      if (error?.code === 'ERR_NETWORK' || error.message.includes('Network Error')) {
        return Promise.reject(new Error("Can't connect to the server. Maybe your network is down?"));
      }
      return Promise.reject(error); // If the error is unknown, reject as is.
    }

    const { message } = error.response.data || {};

    // Handle token expiration case.
    if (message === 'Token expired.') {
      const originalConfig = error.config;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const response = await authApi().refreshTokenCreate({ refreshToken });
          if (response?.token) {
            localStorage.setItem('token', response.token);
            originalConfig.headers.Authorization = `Bearer ${response.token}`;
            return await api.instance(originalConfig);
          }
        } catch (refreshError) {
          console.error('Failed to refresh token', refreshError); // eslint-disable-line no-console
        }
      }

      clearSessionAndReload();
      return Promise.reject(new Error('Could not refresh token.'));
    }

    // Handle not authenticated case.
    if (message === 'Not authenticated.') {
      clearSessionAndReload();
      return Promise.reject(new Error('Not authenticated.'));
    }

    return Promise.reject(error);
  });

  return api;
}

export const client = <T extends HttpClient>(
  Superclass: new (config: ApiConfig) => T,
): T => {
  const api = new Superclass({
    baseURL: VITE_API_URL,
  });
  return intercept(api);
};
