import {
  createContext, ReactNode, useCallback, useContext, useMemo,
} from 'react';
import { ContractLink } from 'api/api';

interface IPermissionContext {
  isActionAllowed: (action: string) => boolean;
  isAnyActionAllowed: (actions: string[]) => boolean;
}

const DEFAULT_PERMISSION_CONTEXT: IPermissionContext = {
  isActionAllowed: () => false,
  isAnyActionAllowed: () => false,
};

const PermissionContext = createContext<IPermissionContext>(DEFAULT_PERMISSION_CONTEXT);

export const usePermissionContext = () => useContext(PermissionContext);

interface Props {
  children: ReactNode;
  permissions?: ContractLink[];
}

export const PermissionProvider = ({ children, permissions }: Props) => {
  const isActionAllowed = useCallback((action: string) => {
    if (!permissions) {
      return false;
    }
    return permissions.some((permission) => permission.rel === action);
  }, [permissions]);

  const isAnyActionAllowed = useCallback((actions: string[]) => {
    if (!permissions) {
      return false;
    }
    return permissions.some((permission) => actions.includes(permission.rel));
  }, [permissions]);

  const value = useMemo(
    () => ({
      isActionAllowed,
      isAnyActionAllowed,
    }),
    [isActionAllowed, isAnyActionAllowed],
  );

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};
