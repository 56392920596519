import * as Sentry from '@sentry/react';
import pack from '../../package.json';

const { VITE_SENTRY_DSN, VITE_SENTRY_SAMPLE_RATE_PERCENT } = import.meta.env;

const config = {
  dsn: VITE_SENTRY_DSN,
  // Version from package.json
  release: pack.version,
};

export const initSentry = (): typeof Sentry | null => {
  if (!config.dsn) {
    return null;
  }
  Sentry.init({
    dsn: config.dsn,
    release: config.release,
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: parseFloat(VITE_SENTRY_SAMPLE_RATE_PERCENT || '10') / 100,
  });

  return Sentry;
};
