import React, {
  createContext, useContext, useMemo, useState,
  useEffect,
} from 'react';
import { TenderDTO } from 'api/api';
import { useTenderByUid } from 'context/TenderDetailsContext/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'constants/QueryKeys';
import { PermissionProvider } from '../PermissionContext';

interface ITenderDetailsContext {
  tender?: TenderDTO;
  isCarrierResponse: boolean;
  isLoading: boolean;
  isError: boolean;
  isRequired: (field: string) => boolean;
  optionalText: (field: string) => undefined | string;
  progressDone: boolean;
  setProgressDone: (value: boolean) => void;
}

const DEFAULT_VALUE: ITenderDetailsContext = {
  tender: undefined,
  isCarrierResponse: false,
  isLoading: false,
  isError: false,
  progressDone: true,
  isRequired: () => false,
  optionalText: () => undefined,
  setProgressDone: () => { },
};

const TenderDetailsContext = createContext<ITenderDetailsContext>(DEFAULT_VALUE);

export const useTenderDetailsContext = () => useContext(TenderDetailsContext);

interface Props {
  tenderUid: string;
  children: React.ReactNode;
}

export const TenderDetailsContextProvider: React.FC<Props> = ({ tenderUid, children }) => {
  const { data: tender, isLoading, isError } = useTenderByUid(tenderUid);
  const [progressDone, setProgressDone] = useState(true);
  const queryClient = useQueryClient();

  function findField(field: string) {
    return [
      ...tender?.contractFieldsLegs || [],
      ...tender?.contractFieldsCost || [],
      ...tender?.contractFieldsTime || [],
    ]?.find(({ name }) => name === field);
  }

  const isRequired = (field: string) => findField(field)?.isRequired ?? false;
  const isActive = (field: string) => findField(field)?.isActive ?? false;

  const optionalText = (field: string) => (isRequired(field)
    ? undefined
    : '(optional)'
  );

  const contextValue = useMemo<ITenderDetailsContext>(() => ({
    tender,
    isCarrierResponse: !!tender?.parentTenderUid,
    isLoading,
    isError,
    isRequired,
    isActive,
    optionalText,
    progressDone,
    setProgressDone,
  }), [tender, isLoading, isError, progressDone, setProgressDone]);

  useEffect(() => {
    if (progressDone) {
      queryClient.invalidateQueries([queryKeys.CONTRACT_VALIDATION_ERRORS, tenderUid]);
      queryClient.invalidateQueries([queryKeys.TENDER_DETAILS, tenderUid]);
      queryClient.invalidateQueries([queryKeys.CONTRACT_LEGS, tenderUid]);
      queryClient.invalidateQueries([queryKeys.TENDER_LEGS, tenderUid]);
    }
  }, [progressDone, tenderUid]);

  return (
    <TenderDetailsContext.Provider value={contextValue}>
      <PermissionProvider permissions={tender?.links}>
        {children}
      </PermissionProvider>
    </TenderDetailsContext.Provider>
  );
};
