/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangePasswordCreatePayload,
  CompanyType,
  ForgotPasswordCreatePayload,
  LoginCreateParams,
  LoginCreatePayload,
  RefreshTokenCreatePayload,
  ResetPasswordCreatePayload,
  UserGroupName,
} from "./api";
import { ContentType, HttpClient, RequestParams } from "./httpClient";

export class Auth<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Return the user that is currently logged in
   *
   * @tags Auth
   * @name GetAuth
   * @summary Get me
   * @request GET:/auth/me
   * @secure
   */
  getAuth = (params: RequestParams = {}) =>
    this.request<
      {
        id: string;
        uid: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string;
        language?: string;
        country?: string | null;
        phone?: string | null;
        isBlocked: boolean;
        isSuperAdmin: boolean;
        invitationConfirmed: boolean;
        groups?: UserGroupName[];
        company?: {
          uid: string;
          /** @format company-type-enum */
          companyType: CompanyType;
          name: string;
          address: string;
          postCode: string;
          city: string;
          country: string;
          website: string;
          phone: string;
          fax?: string | null;
          invoiceAddress: string;
          invoicePostCode: string;
          invoiceCity: string;
          invoiceCountry: string;
          vat: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          carrierDetails?: {
            accountDebit: string;
            accountCredit: string;
            contact: string;
            scac: string;
            oblLength: number | null;
          };
          memberDetails?: {
            eori: string;
            membershipNumber: string;
            osaAccountNumber: string;
            onmiAccountNumber: string;
            osaMember: boolean;
            ossiMember: boolean;
            affiliateOffice: boolean;
          };
          /** @default false */
          fromPortal?: boolean;
        };
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/auth/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Attempt to log in the user with given credentials
   *
   * @tags Auth
   * @name LoginCreate
   * @summary Login user
   * @request POST:/auth/login
   * @secure
   */
  loginCreate = (query: LoginCreateParams, data: LoginCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        id: string;
        token?: string | null;
        refreshToken?: string | null;
        isBlocked: boolean;
        email: string;
        company: {
          uid: string;
          /** @format company-type-enum */
          companyType: CompanyType;
          name: string;
          address: string;
          postCode: string;
          city: string;
          country: string;
          website: string;
          phone: string;
          fax?: string | null;
          invoiceAddress: string;
          invoicePostCode: string;
          invoiceCity: string;
          invoiceCountry: string;
          vat: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          carrierDetails?: {
            accountDebit: string;
            accountCredit: string;
            contact: string;
            scac: string;
            oblLength: number | null;
          };
          memberDetails?: {
            eori: string;
            membershipNumber: string;
            osaAccountNumber: string;
            onmiAccountNumber: string;
            osaMember: boolean;
            ossiMember: boolean;
            affiliateOffice: boolean;
          };
          /** @default false */
          fromPortal?: boolean;
        };
        groups: string[];
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
      },
      | {
          /** Error message */
          message?: string;
        }
      | {
          /** Error message */
          message?: string;
          /** Object containing validation errors */
          data?: object;
        }
    >({
      path: `/auth/login`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description The current user's high-level privileges (e.g. which menu items to show)
   *
   * @tags Auth
   * @name PrivilegesList
   * @summary Logged-in user high-level privileges
   * @request GET:/auth/privileges
   * @secure
   */
  privilegesList = (params: RequestParams = {}) =>
    this.request<
      {
        privileges?: string[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/auth/privileges`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Refresh user authentication token
   *
   * @tags Auth
   * @name RefreshTokenCreate
   * @summary Refresh Token
   * @request POST:/auth/refresh-token
   * @secure
   */
  refreshTokenCreate = (data: RefreshTokenCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        token?: string;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/auth/refresh-token`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Start the forgot password process
   *
   * @tags Auth
   * @name ForgotPasswordCreate
   * @summary Send the forgot password email
   * @request POST:/auth/forgot-password
   * @secure
   */
  forgotPasswordCreate = (data: ForgotPasswordCreatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
        /** Object containing validation errors */
        data?: object;
      }
    >({
      path: `/auth/forgot-password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Reset the password using the token retrieved from forgot-pass action
   *
   * @tags Auth
   * @name ResetPasswordCreate
   * @summary Reset the password
   * @request POST:/auth/reset-password
   * @secure
   */
  resetPasswordCreate = (data: ResetPasswordCreatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      | {
          /** Error message */
          message?: string;
        }
      | {
          /** Error message */
          message?: string;
          /** Object containing validation errors */
          data?: object;
        }
    >({
      path: `/auth/reset-password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Changes the password of an authenticated user using the old and new password
   *
   * @tags Auth
   * @name ChangePasswordCreate
   * @summary Changes the password
   * @request POST:/auth/change-password
   * @secure
   */
  changePasswordCreate = (data: ChangePasswordCreatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      | {
          /** Error message */
          message?: string;
        }
      | {
          /** Error message */
          message?: string;
          /** Object containing validation errors */
          data?: object;
        }
    >({
      path: `/auth/change-password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Invite user to the platform
   *
   * @tags User
   * @name InviteUpdate
   * @summary Invite user
   * @request PUT:/auth/{userUid}/invite
   * @secure
   */
  inviteUpdate = (userUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/auth/${userUid}/invite`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
