import { useQuery } from '@tanstack/react-query';
import { client } from 'api/baseAxios';
import { queryKeys } from 'constants/QueryKeys';
import { Amendments } from 'api/Amendments';

interface HookParams {
  uid?: string;
  enabled: boolean;
}

export const useAmendmentByUid = ({ uid, enabled }: HookParams) => useQuery(
  [queryKeys.CONTRACT_DETAILS, uid],
  () => client(Amendments).amendmentsDetail(uid!),
  {
    refetchOnWindowFocus: false,
    enabled: !!uid && enabled,
  },
);
