/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AutomaticCreatePayload,
  BenchmarkExportListParams,
  ContractActions,
  ContractFieldType,
  ContractLegExtendedDTO,
  ContractLegsCreatePayload,
  ContractLegsListParams,
  ContractLegsLocationsListParams,
  ContractLegsUpdatePayload,
  ContractStatusType,
  EquipmentType,
  ImportUpdateBody,
  LocationType,
  Modality,
  ResponsesCreatePayload,
  TenderExportListParams,
  TenderLegDTO,
  TenderLegsCreatePayload,
  TenderLegsDeleteParams,
  TenderLegsFilteredCreateParams,
  TenderLegsListParams,
  TenderLegsLocationsListParams,
  TenderLegsTenderLegChangeCreatePayload,
  TendersCreatePayload,
  TendersListParams,
  TendersUpdatePayload,
  TerminusType,
  UnlocodeGroupType,
} from "./api";
import { ContentType, HttpClient, RequestParams } from "./httpClient";

export class Tenders<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Return the list of contract legs
   *
   * @tags Tender
   * @name ContractLegsList
   * @summary List contract legs
   * @request GET:/tenders/{tenderUid}/contractLegs
   * @secure
   */
  contractLegsList = ({ tenderUid, ...query }: ContractLegsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: (({
          originCountry: string;
          originPlace: string;
          destinationCountry: string;
          destinationPlace: string;
          /** @format equipment-type-enum */
          equipmentType: EquipmentType;
          /** @format modality-enum */
          modality: Modality;
          origin?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          destination?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
        } & {
          templateLegId: string;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
        }) & {
          groupUuid: string;
          legUuid: string;
          contractCode?: string | null;
          costBaseRate: number;
          costBaseRateCurrency: string;
          timeTransit: number;
          declined: boolean;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          tradeLaneOrigin?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          tradeLaneDestination?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          isFromAnAmendment?: boolean | null;
          isFuture?: boolean | null;
        })[];
      },
      any
    >({
      path: `/tenders/${tenderUid}/contractLegs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add contract leg to a tender
   *
   * @tags Tender
   * @name ContractLegsCreate
   * @summary Add contract leg
   * @request POST:/tenders/{tenderUid}/contractLegs
   * @secure
   */
  contractLegsCreate = (tenderUid: string, data: ContractLegsCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        legUuid: string;
        groupUuid: string;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/contractLegs`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Return the paginated list of locations for contract legs
   *
   * @tags Tender
   * @name ContractLegsLocationsList
   * @summary List available contract legs location
   * @request GET:/tenders/{tenderUid}/contractLegs/locations
   * @secure
   */
  contractLegsLocationsList = ({ tenderUid, ...query }: ContractLegsLocationsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          filterValue?: string;
          /** @format location-type-enum */
          type?: LocationType;
          unlocode?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          unlocodeGroup?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          /** @format terminus-type-enum */
          terminus?: TerminusType;
        }[];
      },
      any
    >({
      path: `/tenders/${tenderUid}/contractLegs/locations`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Return a contract leg
   *
   * @tags Tender
   * @name ContractLegsDetail
   * @summary Get contract leg
   * @request GET:/tenders/{tenderUid}/contractLegs/{legUuid}
   * @secure
   */
  contractLegsDetail = (tenderUid: string, legUuid: string, params: RequestParams = {}) =>
    this.request<ContractLegExtendedDTO, any>({
      path: `/tenders/${tenderUid}/contractLegs/${legUuid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update contract leg
   *
   * @tags Tender
   * @name ContractLegsUpdate
   * @summary Update contract leg
   * @request PUT:/tenders/{tenderUid}/contractLegs/{groupUuid}
   * @secure
   */
  contractLegsUpdate = (
    tenderUid: string,
    groupUuid: string,
    data: ContractLegsUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<
      ContractLegExtendedDTO,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/contractLegs/${groupUuid}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Return the list of tenders
   *
   * @tags Tender
   * @name TendersList
   * @summary List tenders
   * @request GET:/tenders
   * @secure
   */
  tendersList = (query: TendersListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          uid: string;
          hasValidationErrors?: boolean;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          title: string;
          parentTenderUid?: string;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          companiesWithAccess?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          }[];
          contractAccess?: {
            companyUid: string;
            companyName: string;
            /** @format date-time */
            dueDate?: Date | null;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          defaultResponseDueAt?: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Adds a contract
   *
   * @tags Tender
   * @name TendersCreate
   * @summary Creates a new Tender
   * @request POST:/tenders
   * @secure
   */
  tendersCreate = (data: TendersCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors?: boolean;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        title: string;
        parentTenderUid?: string;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        companiesWithAccess?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        }[];
        contractAccess?: {
          companyUid: string;
          companyName: string;
          /** @format date-time */
          dueDate?: Date | null;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        defaultResponseDueAt?: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description Adds a new set of tenders automatically using the given tenders as a base
   *
   * @tags Tender
   * @name AutomaticCreate
   * @summary Creates new tenders automatically
   * @request POST:/tenders/automatic
   * @secure
   */
  automaticCreate = (data: AutomaticCreatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/automatic`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Return an Excel file benchmarking all tender response legs of the contract year
   *
   * @tags Tender
   * @name BenchmarkExportList
   * @summary Export a benchmark Excel sheet
   * @request GET:/tenders/benchmarkExport
   * @secure
   */
  benchmarkExportList = (query: BenchmarkExportListParams, params: RequestParams = {}) =>
    this.request<
      File,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/benchmarkExport`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Return the Tender with the given uid
   *
   * @tags Tender
   * @name TendersDetail
   * @summary Get single Tender
   * @request GET:/tenders/{tenderUid}
   * @secure
   */
  tendersDetail = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors?: boolean;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        title: string;
        parentTenderUid?: string;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        companiesWithAccess?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        }[];
        contractAccess?: ({
          companyUid: string;
          companyName: string;
          /** @format date-time */
          dueDate?: Date | null;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        } & {
          isDeletable?: boolean;
        })[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        defaultResponseDueAt?: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update a tender
   *
   * @tags Tender
   * @name TendersUpdate
   * @summary Update tender
   * @request PUT:/tenders/{tenderUid}
   * @secure
   */
  tendersUpdate = (tenderUid: string, data: TendersUpdatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors?: boolean;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        title: string;
        parentTenderUid?: string;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        companiesWithAccess?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        }[];
        contractAccess?: ({
          companyUid: string;
          companyName: string;
          /** @format date-time */
          dueDate?: Date | null;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        } & {
          isDeletable?: boolean;
        })[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        defaultResponseDueAt?: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description deletes a tender based on Uid
   *
   * @tags Tender
   * @name TendersDelete
   * @summary delete tender
   * @request DELETE:/tenders/{tenderUid}
   * @secure
   */
  tendersDelete = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Update a tender's legs through an imported Excel sheet
   *
   * @tags Tender
   * @name ImportUpdate
   * @summary Overwrite tender with import
   * @request PUT:/tenders/{tenderUid}/import
   * @secure
   */
  importUpdate = (tenderUid: string, data: ImportUpdateBody, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/import`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Get status for tender import background jobs
   *
   * @tags Tender
   * @name ImportList
   * @summary Get status for tender import
   * @request GET:/tenders/{tenderUid}/import
   * @secure
   */
  importList = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      {
        progress?: number;
        hasValidationErrors?: boolean;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/import`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Updates the status of a tender to accepted
   *
   * @tags Tender
   * @name AcceptUpdate
   * @summary Updates the status of a tender to accepted
   * @request PUT:/tenders/{tenderUid}/accept
   * @secure
   */
  acceptUpdate = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/accept`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Updates the status of a tender to declined.
   *
   * @tags Tender
   * @name DeclineUpdate
   * @summary Updates the status of a tender to declined.
   * @request PUT:/tenders/{tenderUid}/decline
   * @secure
   */
  declineUpdate = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/decline`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Updates the status of a tender to published
   *
   * @tags Tender
   * @name PublishUpdate
   * @summary Updates the status of a tender to published
   * @request PUT:/tenders/{tenderUid}/publish
   * @secure
   */
  publishUpdate = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/publish`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Removes the published status from a tender
   *
   * @tags Tender
   * @name UnpublishUpdate
   * @summary Removes the published status from a tender
   * @request PUT:/tenders/{tenderUid}/unpublish
   * @secure
   */
  unpublishUpdate = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/unpublish`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Adds a tender response to the given tender
   *
   * @tags Tender
   * @name ResponsesCreate
   * @summary Creates a new tender response
   * @request POST:/tenders/{tenderUid}/responses
   * @secure
   */
  responsesCreate = (tenderUid: string, data: ResponsesCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors?: boolean;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        title: string;
        parentTenderUid?: string;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        companiesWithAccess?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        }[];
        contractAccess?: {
          companyUid: string;
          companyName: string;
          /** @format date-time */
          dueDate?: Date | null;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        defaultResponseDueAt?: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/responses`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get validation errors for tender import background jobs
   *
   * @tags Tender
   * @name ValidationErrorsList
   * @summary Get validation errors for tender import
   * @request GET:/tenders/{tenderUid}/validationErrors
   * @secure
   */
  validationErrorsList = (tenderUid: string, params: RequestParams = {}) =>
    this.request<
      {
        validationErrors?: {
          errorRow?: number | null;
          errorDescription?: string;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/validationErrors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Export tender with tender legs as excel
   *
   * @tags Tender
   * @name TenderExportList
   * @summary Export tender as excel
   * @request GET:/tenders/{tenderUid}/tenderExport
   * @secure
   */
  tenderExportList = ({ tenderUid, ...query }: TenderExportListParams, params: RequestParams = {}) =>
    this.request<
      File,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/tenderExport`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Return the list of tender legs
   *
   * @tags Tender
   * @name TenderLegsList
   * @summary List tender legs
   * @request GET:/tenders/{tenderUid}/tenderLegs
   * @secure
   */
  tenderLegsList = ({ tenderUid, ...query }: TenderLegsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: ({
          originCountry: string;
          originPlace: string;
          destinationCountry: string;
          destinationPlace: string;
          /** @format equipment-type-enum */
          equipmentType: EquipmentType;
          /** @format modality-enum */
          modality: Modality;
          origin?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          destination?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
        } & {
          templateLegId: string;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
        })[];
      },
      any
    >({
      path: `/tenders/${tenderUid}/tenderLegs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add tender leg to a tender
   *
   * @tags Tender
   * @name TenderLegsCreate
   * @summary Add tender leg
   * @request POST:/tenders/{tenderUid}/tenderLegs
   * @secure
   */
  tenderLegsCreate = (tenderUid: string, data: TenderLegsCreatePayload, params: RequestParams = {}) =>
    this.request<
      TenderLegDTO,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/tenderLegs`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove tender legs from a tender based on filter.
   *
   * @tags Tender
   * @name TenderLegsDelete
   * @summary Delete tender legs
   * @request DELETE:/tenders/{tenderUid}/tenderLegs
   * @secure
   */
  tenderLegsDelete = ({ tenderUid, ...query }: TenderLegsDeleteParams, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/tenderLegs`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Return the paginated list of locations for tender legs
   *
   * @tags Tender
   * @name TenderLegsLocationsList
   * @summary List available tender legs location
   * @request GET:/tenders/{tenderUid}/tenderLegs/locations
   * @secure
   */
  tenderLegsLocationsList = ({ tenderUid, ...query }: TenderLegsLocationsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          filterValue?: string;
          /** @format location-type-enum */
          type?: LocationType;
          unlocode?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          unlocodeGroup?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          /** @format terminus-type-enum */
          terminus?: TerminusType;
        }[];
      },
      any
    >({
      path: `/tenders/${tenderUid}/tenderLegs/locations`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Removes one leg and adds another from a tender. Combines removing the current leg (delete) with adding a new leg (post)
   *
   * @tags Tender
   * @name TenderLegsTenderLegChangeCreate
   * @summary Change tender leg
   * @request POST:/tenders/{tenderUid}/tenderLegs/tenderLegChange
   * @secure
   */
  tenderLegsTenderLegChangeCreate = (
    tenderUid: string,
    data: TenderLegsTenderLegChangeCreatePayload,
    params: RequestParams = {},
  ) =>
    this.request<
      TenderLegDTO,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/tenderLegs/tenderLegChange`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Add tender legs to a tender based on filter
   *
   * @tags Tender
   * @name TenderLegsFilteredCreate
   * @summary Add tender legs from filter (only superadmin)
   * @request POST:/tenders/{tenderUid}/tenderLegs/filtered
   * @secure
   */
  tenderLegsFilteredCreate = ({ tenderUid, ...query }: TenderLegsFilteredCreateParams, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/tenders/${tenderUid}/tenderLegs/filtered`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
}
