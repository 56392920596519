import { useQuery } from '@tanstack/react-query';
import { client } from 'api/baseAxios';
import { Contracts } from 'api/Contracts';
import { queryKeys } from 'constants/QueryKeys';

interface HookParams {
  uid?: string;
  enabled: boolean;
}

export const useContractByUid = ({ uid, enabled }: HookParams) => useQuery(
  [queryKeys.CONTRACT_DETAILS, uid],
  () => client(Contracts).contractsDetail(uid!),
  {
    refetchOnWindowFocus: false,
    enabled: !!uid && enabled,
  },
);
