import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { AmendmentDTO, ContractDTO } from 'api/api';
import { useContractByUid } from 'context/ContractDetailsContext/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'constants/QueryKeys';
import { PermissionProvider } from '../PermissionContext';
import { useAmendmentByUid } from './hooks/useAmendmentByUid';

interface IContractDetailsContext {
  contract?: ContractDTO | AmendmentDTO;
  isAmendment: boolean;
  isLoading: boolean;
  isError: boolean;
  progressDone: boolean;
  setProgressDone: (value: boolean) => void;
}

const DEFAULT_VALUE: IContractDetailsContext = {
  contract: undefined,
  isAmendment: false,
  isLoading: false,
  isError: false,
  setProgressDone: () => { },
  progressDone: true,
};

const ContractDetailsContext = createContext<IContractDetailsContext>(DEFAULT_VALUE);

export const useContractDetailsContext = () => useContext(ContractDetailsContext);

interface Props {
  contractUid: string;
  asAmendment: boolean;
  children: React.ReactNode;
}

export const ContractDetailsContextProvider: React.FC<Props> = ({
  contractUid: uid,
  children, asAmendment,
}) => {
  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractError,
  } = useContractByUid({ uid, enabled: !asAmendment });
  const [progressDone, setProgressDone] = useState(true);
  const queryClient = useQueryClient();
  const {
    data: amendment,
    isLoading: isAmendmentLoading,
    isError: isAmendmentError,
  } = useAmendmentByUid({ uid, enabled: asAmendment });

  const contextValue = useMemo<IContractDetailsContext>(
    () => ({
      contract: contract || amendment,
      isAmendment: !!contract?.isAmendment || !!amendment?.parentContractUid,
      isLoading: isContractLoading || isAmendmentLoading,
      isError: isContractError || isAmendmentError,
      progressDone,
      setProgressDone,
    }),
    [contract, amendment, isContractLoading, isContractError,
      isAmendmentLoading, isAmendmentError, progressDone, setProgressDone],
  );

  useEffect(() => {
    if (progressDone) {
      queryClient.invalidateQueries([queryKeys.AMENDMENT_VALIDATION_ERRORS, uid]);
      queryClient.invalidateQueries([queryKeys.CONTRACT_DETAILS, uid]);
      queryClient.invalidateQueries([queryKeys.CONTRACT_LEGS, uid]);
      queryClient.invalidateQueries([queryKeys.AMENDMENT_LEG_CHANGES_LIST, uid]);
    }
  }, [progressDone, uid]);

  return (
    <ContractDetailsContext.Provider value={contextValue}>
      <PermissionProvider permissions={contract?.links}>
        {children}
      </PermissionProvider>
    </ContractDetailsContext.Provider>
  );
};
