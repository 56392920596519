import { useQuery } from '@tanstack/react-query';
import { client } from 'api/baseAxios';
import { Tenders } from 'api/Tenders';
import { queryKeys } from 'constants/QueryKeys';

export const useTenderByUid = (uid?: string) => useQuery(
  [queryKeys.TENDER_DETAILS, uid],
  () => client(Tenders).tendersDetail(uid!),
  {
    enabled: !!uid,
    refetchOnWindowFocus: false,
  },
);
